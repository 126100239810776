import React from 'react';
import { graphql } from 'gatsby';

import { grid } from './home.module.scss';
import { IQueryAllResult } from '../models/query-all-result.model';
import { IBannerCollection } from '../models/banner-collection.model';
import { INews } from '../models/news.model';
import { IEvent } from '../models/event.model';
import { ILogo } from '../models/logo.model';
import { getNodes } from '../utils/get-nodes';

import MainLayout from '../layouts/main-layout';
import Banner from '../components/organisms/banner';
import HomeNews from '../components/organisms/home-news';
import HomeEvents from '../components/organisms/home-events';
import SliderWithLogotypes from '../components/organisms/slider-with-logotypes';

interface IHomeQueryResults {
    mainBannerCollection?: IBannerCollection;
    allNews: IQueryAllResult<INews>;
    allEvent: IQueryAllResult<IEvent> & { totalCount: number };
    allLogo: IQueryAllResult<ILogo>;
}

interface IHomeProps {
    data: IHomeQueryResults;
}

const Home: React.FC<IHomeProps> = ({ data }) => {
    const banner = data.mainBannerCollection?.banners[0];
    const news = getNodes(data.allNews);
    const logos = getNodes(data.allLogo);

    return (
        <MainLayout isFloatingBannerVisibleOnStart={true}>
            <div className={grid}>
                <Banner banner={banner} />
                <HomeNews news={news} />
                <HomeEvents events={data.allEvent} limit={3} />
                <SliderWithLogotypes logos={logos} />
            </div>
        </MainLayout>
    );
};

export const query = graphql`
    query {
        mainBannerCollection: bannerCollection(
            # We know that main banner collection and banner in this collection have ids equal to 1
            collectionId: { eq: 1 }
            banners: { elemMatch: { bannerId: { eq: 1 } } }
        ) {
            banners {
                bannerId
                title
                subtitle
                buttonText
                buttonUrl
                media {
                    ...mediaFields
                }
            }
        }

        allNews(sort: { order: DESC, fields: publishedAt }, limit: 3) {
            edges {
                node {
                    ...newsCardFields
                }
            }
        }

        allEvent(limit: 3) {
            edges {
                node {
                    eventId
                    title
                    content
                    date
                    url
                }
            }
            totalCount
        }

        allLogo(sort: { fields: sequence, order: ASC }) {
            edges {
                node {
                    logoId
                    url
                    sequence
                    media {
                        ...mediaFields
                    }
                }
            }
        }
    }
`;

export default Home;
