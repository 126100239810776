import React, { useEffect, useState } from 'react';

import { wrapper, label, dots } from './loader.module.scss';

import useTranslations from '../../hooks/use-translations';

import LoaderIcon from '../../assets/images/svg/loader.svg';

export interface ILoaderProps {
    className?: string;
}

const Loader = ({ className = '' }: ILoaderProps) => {
    const t = useTranslations('Loader');
    const [number, setNumber] = useState(1);

    const changeDotsNumber = (number: number) => {
        if (number === 3) return 1;
        return ++number;
    };

    useEffect(() => {
        const dotInterval = setInterval(() => {
            setNumber((number) => {
                return changeDotsNumber(number);
            });
        }, 670);
        return () => clearInterval(dotInterval);
    }, []);

    return (
        <div className={`${wrapper} ${className}`}>
            <LoaderIcon />
            <span className={label}>
                {t.loading}
                <span className={dots}>
                    {[...Array(number)].map((_e, i) => (
                        <span key={i}>.</span>
                    ))}
                </span>
            </span>
        </div>
    );
};

export default Loader;
