import React from 'react';

import { section, heading, slider } from './slider-with-logotypes.module.scss';
import { ILogo } from '../../models/logo.model';
import useTranslations from '../../hooks/use-translations';

import Heading from '../atoms/heading';
import Slider from '../hoc/slider';
import PartnerLogo from '../atoms/partner-logo';

interface ISliderWithLogotypesProps {
    className?: string;
    logos: ILogo[];
}

const SliderWithLogotypes: React.FC<ISliderWithLogotypesProps> = ({ className = '', logos }) => {
    const t = useTranslations('SliderWithLogotypes');

    return (
        <section className={`${section} ${className}`}>
            <Heading numberOfStyle={2} className={heading}>
                {t.heading}
            </Heading>
            <Slider className={slider} {...sliderOptions}>
                {logos.map((logo) => (
                    <PartnerLogo key={`partner-logotype-${logo.logoId}`} logo={logo} />
                ))}
            </Slider>
        </section>
    );
};

const sliderOptions = {
    breakpoints: {
        400: {
            slidesPerView: 2,
            spaceBetween: 20,
        },
        550: {
            slidesPerView: 3,
            spaceBetween: 30,
        },
        700: {
            slidesPerView: 4,
            spaceBetween: 40,
        },
        850: {
            slidesPerView: 5,
            spaceBetween: 45,
        },
        1000: {
            slidesPerView: 6,
            spaceBetween: 45,
        },
        1150: {
            slidesPerView: 7,
            spaceBetween: 45,
        },
    },
};

export default SliderWithLogotypes;
