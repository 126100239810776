import React from 'react';

import {
    section,
    heading,
    subheading,
    grid,
    button,
    controlsWrapper,
    errorContainer,
    loader,
} from './home-events.module.scss';
import { IEvent } from '../../models/event.model';
import { IQueryAllResult } from '../../models/query-all-result.model';
import { getEvents } from '../../api-ssr/events';
import { getNodes } from '../../utils/get-nodes';
import useTranslations from '../../hooks/use-translations';
import useInfiniteQuery from '../../hooks/use-infinite-query';

import EventCard from '../molecules/event-card';
import Heading from '../atoms/heading';
import Button from '../atoms/button';
import Content from '../atoms/content';
import ErrorHandler from '../atoms/error-handler';
import Loader from '../atoms/loader';

interface IHomeEventsProps {
    className?: string;
    events: IQueryAllResult<IEvent> & { totalCount: number };
    limit?: number;
}

const HomeEvents: React.FC<IHomeEventsProps> = ({ className = '', events, limit = 3 }) => {
    const t = useTranslations('HomeEvents');
    const { data, fetchNextPage, isFetching, isError, hasNextPage, error } = useInfiniteQuery(
        'events',
        getEvents,
        {
            perPage: limit,
            totalCount: events.totalCount,
            initialItems: getNodes(events),
        }
    );

    return (
        <section className={`${section} ${className}`}>
            <Heading numberOfStyle={2} className={heading}>
                {t.heading}
            </Heading>
            <Heading numberOfStyle={5} className={subheading} isRegular={true} Tag={'p'}>
                {t.subheading}
            </Heading>
            <div className={grid}>
                {data?.map((event) => (
                    <EventCard key={event.eventId} data={event} />
                ))}
            </div>
            {(hasNextPage || isError) && (
                <div className={controlsWrapper}>
                    {isFetching && <Loader className={loader} />}
                    {!isFetching && isError && (
                        <div className={errorContainer}>
                            <ErrorHandler
                                error={error}
                                onRequestError={(message) => (
                                    <Content numberOfStyle={2}>{message}</Content>
                                )}
                                onResponseError={(data, error) => {
                                    return (
                                        <>
                                            {data.messages.map((message) => (
                                                <Content numberOfStyle={2}>
                                                    {error?.message}: {message.content}
                                                </Content>
                                            ))}
                                        </>
                                    );
                                }}
                            />
                        </div>
                    )}
                    {!isFetching && !isError && (
                        <Button className={button} theme={'dark'} onClick={() => fetchNextPage()}>
                            {t.button}
                        </Button>
                    )}
                </div>
            )}
        </section>
    );
};

export default HomeEvents;
