import { ICollectionResponse } from '../models/api-models.model';
import { IEvent } from '../models/event.model';
import { TQueryFunction } from '../hooks/use-infinite-query';
import fetchApi from './fetch-api';

export const getEvents: TQueryFunction<IEvent, ICollectionResponse<IEvent>> = async ({
    pageParam = 1,
    perPage,
}) => {
    return await fetchApi<ICollectionResponse<IEvent>>('get', 'events', {
        params: { 'per-page': perPage, page: pageParam },
    });
};
