import {
    QueryFunctionContext,
    QueryKey,
    useInfiniteQuery as useInfiniteQueryParent,
    UseInfiniteQueryOptions,
} from 'react-query';
import { AxiosError } from 'axios';

import { ICollectionResponse, IErrorResponse } from '../models/api-models.model';

export interface IUseInfiniteQueryOptions<
    TInitialItems,
    TQueryFnData extends ICollectionResponse<TInitialItems>,
    TError = unknown,
    TQueryKey extends QueryKey = QueryKey
> extends Omit<
        UseInfiniteQueryOptions<TQueryFnData, TError, TQueryFnData, TQueryFnData, TQueryKey>,
        'queryKey' | 'queryFn'
    > {
    initialItems: TInitialItems[];
    perPage: number;
    totalCount: number;
}

export type TQueryFunctionContext<TQueryKey extends QueryKey = QueryKey> =
    QueryFunctionContext<TQueryKey> & { perPage: number };

export type TQueryFunction<
    TInitialItems,
    TQueryFnData extends ICollectionResponse<TInitialItems>,
    TQueryKey extends QueryKey = QueryKey
> = (context: TQueryFunctionContext<TQueryKey>) => TQueryFnData | Promise<TQueryFnData>;

export default function useInfiniteQuery<
    TInitialItems,
    TError = AxiosError<IErrorResponse>,
    TQueryKey extends QueryKey = QueryKey
>(
    queryKey: TQueryKey,
    queryFn: TQueryFunction<TInitialItems, ICollectionResponse<TInitialItems>, TQueryKey>,
    options: IUseInfiniteQueryOptions<
        TInitialItems,
        ICollectionResponse<TInitialItems>,
        TError,
        TQueryKey
    >
) {
    const { initialItems, totalCount, perPage } = options;
    const { data, ...rest } = useInfiniteQueryParent(
        queryKey,
        (context) => queryFn({ ...context, perPage }),
        {
            initialData: () => ({
                pageParams: [1],
                pages: [
                    {
                        items: initialItems,
                        pagination: {
                            currentPage: 1,
                            pageCount: Math.ceil(totalCount / 3),
                            perPage,
                            totalCount,
                        },
                    },
                ],
            }),
            getNextPageParam: (lastPage) => {
                if (lastPage.pagination.currentPage >= lastPage.pagination.pageCount) return;

                return lastPage.pagination.currentPage + 1;
            },
            ...options,
        }
    );

    return {
        data: data?.pages.map((page) => page.items).flat(),
        ...rest,
    };
}
