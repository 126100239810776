import React from 'react';

import { partnerLogo } from './partner-logo.module.scss';
import { ILogo } from '../../models/logo.model';

import RatioImage from './ratio-image';

export interface IPartnerLogoProps {
    logo: ILogo;
}

export default function PartnerLogo({ logo }: IPartnerLogoProps) {
    const { logoId, media, url } = logo;

    const image = (
        <RatioImage key={logoId} media={media} objectFit={'contain'} className={partnerLogo} />
    );

    if (!url) {
        return image;
    }

    return (
        <a href={url} target={'_blank'} rel={'noopener noreferrer'}>
            {image}
        </a>
    );
}
