import React from 'react';

import { section, heading, subheading } from './home-news.module.scss';
import { INews } from '../../models/news.model';
import useTranslations from '../../hooks/use-translations';

import Heading from '../atoms/heading';
import NewsListing from '../molecules/news-listing';

interface IHomeNewsProps {
    className?: string;
    news: INews[];
}

const HomeNews: React.FC<IHomeNewsProps> = ({ className = '', news }) => {
    const t = useTranslations('HomeNews');

    return (
        <section className={`${section} ${className}`}>
            <Heading numberOfStyle={2} className={heading}>
                {t.heading}
            </Heading>
            <Heading numberOfStyle={5} isRegular={true} className={subheading}>
                {t.subheading}
            </Heading>
            <NewsListing news={news} isFirstNewsFeatured={true} />
        </section>
    );
};

export default HomeNews;
