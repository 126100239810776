import { AxiosError } from 'axios';

import { IErrorResponse } from '../../models/api-models.model';

export interface IErrorHandlerProps {
    error: AxiosError<IErrorResponse>;
    onResponseError: (data: IErrorResponse, error: AxiosError<IErrorResponse>) => JSX.Element;
    onRequestError: (message: string) => JSX.Element;
}

export default function ErrorHandler({
    error,
    onRequestError,
    onResponseError,
}: IErrorHandlerProps) {
    if (!error) return null;

    if (error.response?.data) return onResponseError(error.response.data, error);

    return onRequestError(error.message);
}
