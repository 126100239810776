import React, { useState } from 'react';

import {
    section,
    player,
    contentGrid,
    content,
    heading,
    description,
    speaker,
    pause,
} from './banner.module.scss';
import { IBanner } from '../../models/banner.model';
import { IHeadingProps } from '../atoms/heading';
import useTranslations from '../../hooks/use-translations';
import useMedia from '../../hooks/use-media';
import relations from '../../config/relations';
import SpeakerIcon from '../../assets/images/svg/speaker.svg';
import SpeakerOffIcon from '../../assets/images/svg/speaker-off.svg';
import PauseIcon from '../../assets/images/svg/pause.svg';
import PlayIcon from '../../assets/images/svg/play.svg';

import VideoPlayer from '../molecules/video-player';
import Heading from '../atoms/heading';
import Button from '../atoms/button';
import SquareButton from '../atoms/square-button';
import AccessibleContent from '../atoms/accessible-content';

interface IBannerProps {
    className?: string;
    banner?: IBanner;
    HeadingTag?: IHeadingProps['Tag'];
}

const Banner: React.FC<IBannerProps> = ({ className = '', banner, HeadingTag = 'h1' }) => {
    const t = useTranslations('Banner');
    const [isMuted, setIsMuted] = useState(true);
    const [isPaused, setIsPaused] = useState(false);
    const { title, subtitle, buttonText, buttonUrl, media } = banner || {};
    const { url } = useMedia({ media, relation: relations.mainImage });

    const toggleMuting = () => setIsMuted(!isMuted);
    const togglePause = () => setIsPaused(!isPaused);

    if (!banner || !url) return null;

    return (
        <section className={`${section} ${className}`}>
            <VideoPlayer
                className={player}
                url={url}
                isMuted={isMuted}
                playing={!isPaused}
                playsinline
                loop
                objectFit="cover"
            />
            <div className={contentGrid}>
                <div className={content}>
                    {title && (
                        <Heading
                            className={heading}
                            numberOfStyle={1}
                            Tag={HeadingTag}
                            theme={'light'}
                        >
                            {title}
                        </Heading>
                    )}
                    {subtitle && (
                        <Heading
                            numberOfStyle={4}
                            isRegular={true}
                            theme={'light'}
                            Tag={'p'}
                            className={description}
                        >
                            {subtitle}
                        </Heading>
                    )}
                    {buttonUrl && (
                        <Button as={'link'} to={buttonUrl} theme={'white'} isOutlined={true}>
                            {buttonText ? buttonText : t.button}
                        </Button>
                    )}
                    <SquareButton className={pause} onClick={togglePause}>
                        <AccessibleContent screenReadersContent={t.speaker} Tag={'span'}>
                            {isPaused ? <PauseIcon /> : <PlayIcon />}
                        </AccessibleContent>
                    </SquareButton>
                    <SquareButton className={speaker} onClick={toggleMuting}>
                        <AccessibleContent screenReadersContent={t.speaker} Tag={'span'}>
                            {isMuted ? <SpeakerOffIcon /> : <SpeakerIcon />}
                        </AccessibleContent>
                    </SquareButton>
                </div>
            </div>
        </section>
    );
};

export default Banner;
