import React from 'react';

import { link, title as titleClass } from './event-card.module.scss';
import { IEvent } from '../../models/event.model';
import { useDate } from '../../hooks/use-date';
import useTranslations from '../../hooks/use-translations';

import Heading from '../atoms/heading';
import Content from '../atoms/content';
import AccessibleHeading from '../atoms/accessible-heading';
import Link from '../atoms/link';

interface IEventCardProps {
    className?: string;
    data: IEvent;
}

const EventCard: React.FC<IEventCardProps> = ({ className = '', data }) => {
    const t = useTranslations('EventCard');
    const { date, title, content, url } = data || {};
    const dateTime = useDate(date, ['DD', 'MM', 'YYYY'], '/');
    const formattedDate = useDate(date, ['DD', 'MMM', 'YYYY']);

    return (
        <article className={className}>
            <Link className={link} to={url} ChildrenWrapper={React.Fragment}>
                <AccessibleHeading
                    screenReadersContent={
                        <>
                            <span>{t.predate}</span>{' '}
                            <time dateTime={dateTime}>{formattedDate}</time>.
                        </>
                    }
                    numberOfStyle={5}
                    Tag={'p'}
                >
                    {formattedDate}
                </AccessibleHeading>
                <div>
                    <Heading className={titleClass} numberOfStyle={5} Tag={'h3'}>
                        {title}
                    </Heading>
                    <Content>{content}</Content>
                </div>
            </Link>
        </article>
    );
};

export default EventCard;
